<form [formGroup]="form">
  <quill-editor
    customToolbarPosition="top"
    #definitionEditor
    [class.disabled]="isDisabled"
    [modules]="quillModules"
    [formControl]="internalControl"
    [readOnly]="isReadOnly"
    [required]="isRequired"
    [disabled]="isDisabled"
    (onFocus)="onDefinitionFocus()"
    (focusin)="focusIn($event)"
    (onEditorCreated)="onEditorCreated($event)"
    (onContentChanged)="change($event)"
  >
    <div quill-editor-toolbar [class.disabled]="isDisabled">

      <span class="ql-formats">
        <button type="button" class="ql-bold"
          matTooltip="Bold"
          [matTooltipPosition]="tooltipPosition"></button>
        <button type="button" class="ql-italic"
          matTooltip="Italic"
          [matTooltipPosition]="tooltipPosition"></button>
        <button type="button" class="ql-underline"
          matTooltip="Underline"
          [matTooltipPosition]="tooltipPosition"></button>
      </span>

      <span class="ql-formats">
        <button type="button" class="ql-list" value="ordered"
          matTooltip="Numbered list"
          [matTooltipPosition]="tooltipPosition"
        ></button>
        <button type="button" class="ql-list" value="bullet"
          matTooltip="Bullet list"
          [matTooltipPosition]="tooltipPosition"
        ></button>
      </span>

      <span class="ql-formats">
        <button type="button" class="ql-link"
          matTooltip="Add link"
          [matTooltipPosition]="tooltipPosition"
        ></button>
      </span>

      <span class="ql-formats">
        <button type="button" class="ql-indent" value="-1"
          matTooltip="Outdent"
          [matTooltipPosition]="tooltipPosition">
        </button>
        <button type="button" class="ql-indent" value="+1"
          matTooltip="Indent"
          [matTooltipPosition]="tooltipPosition">
        </button>
      </span>

      <span class="ql-formats">
        <button type="button" class="ql-add-variable material-icons-round"
          matTooltip="Add variable"
          [matTooltipPosition]="tooltipPosition"
          (click)="onAddVariableClicked()"
          *ngIf="showBtnModal">
          add_circle
        </button>
      </span>
    </div>
  </quill-editor>
</form>
